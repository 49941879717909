#cursor {
    height: clamp(16px, 8.6vw, 40px);
    vertical-align: bottom;
    position: relative;
    border-left: 0.05em solid #ffffff;
    animation: blinkwhite 0.7s steps(1) infinite;
}

@keyframes blinkwhite {
    50% {
        border-color: transparent;
    }
}
