.badge {
    margin: 18px auto;
    background: #fff;
    border-radius: 16px;
    display: flex;
    box-shadow: 0px 0px 20px wheat;
    p {
        text-align: justify;
        color: darkslategrey;
        padding: 16px;
    }
    img {
        width: 40px;
        height: 40px;
        margin: 16px;
        align-self: center;
    }
    bold {
        font-weight: 900;
        color: black;
        background: bisque;
        border-radius: 10px;
        padding: 4px;
    }
}
.mxw300 {
    max-width: 300px;
}
h3 {
    width: 100%;
}
.mxw90p{
    max-width: 78%;
}
.resultbox header {
    padding: 0px;
}
