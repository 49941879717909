.toolbox {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
    min-width: 260px;
    max-width: 600px;
    animation: fadein 1s;
    justify-content: center;
    padding-bottom: 24px;
}
main {
    header {
        padding: 16px;
        .title {
            color: rgb(0, 0, 0);
            font-family: "Open Sans", sans-serif;
            font-size: 32px;
            display: block;
        }
        .sub-title {
            display: block;
        }
        span {
            padding: 0;
        }
    }
}
.resultbox {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    min-width: 260px;
    animation: fadein 1s;
    justify-content: center;
    padding-bottom: 24px;
    header {
        padding: 16px;
        .title {
            color: rgb(0, 0, 0);
            font-family: "Open Sans", sans-serif;
            font-size: 32px;
            display: block;
            margin-bottom: 12px;
        }
        .sub-title {
            display: block;
            margin-bottom: 12px;
        }
        span {
            padding: 0;
        }
    }
}
header {
    font-size: larger;
    font-weight: 600;
}
.radarbox {
    margin: 32px auto auto auto;
    width: 100%;
    min-width: 260px;
    max-width: 360px;
    max-height: 350px;
    justify-content: center;
    padding-bottom: 24px;
}
.radarbox-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    min-width: 260px;
    animation: fadein 1s;
    justify-content: center;
    padding-bottom: 24px;
}
.recharts-label {
    tspan {
        z-index: 100;
        font-family: "Open Sans", cursive;
    }
}
.recharts-reference-line {
    tspan {
        z-index: 100;
        font-family: "Zilla Slab Highlight", cursive;
    }
}
.recharts-responsive-container {
    margin-bottom: 28px;
}
