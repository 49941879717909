@import url('https://fonts.googleapis.com/css2?family=New+Tegomin&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab+Highlight:wght@700&display=swap');
html{
  width:100%;
  height:100%;
  background: whitesmoke;
}
*{
  font-family: 'New Tegomin', serif;
  box-sizing: border-box;
  margin: 0;
}